<template>
  <b-card>
    <div class="justify-content-end d-flex flex-wrap">
      <!-- <b-button
        v-if="parseInt(form.situacao) === 10"
        class="mr-1"
        variant="primary"
        @click="atestado"
        >Atestado</b-button
      > -->

      <!-- <b-button
        v-if="parseInt(form.situacao) === 10"
        class="mr-1"
        variant="primary"
        @click="receita"
        >Receita</b-button
      >

      <b-button
        v-if="parseInt(form.situacao) === 10"
        class="mr-1"
        variant="primary"
        @click="declaracao"
        >Declaração</b-button
      > -->

      <!-- <b-button
        v-if="parseInt(form.situacao) === 10"
        class="mr-1"
        variant="primary"
        @click="exame"
        >Solicitação de Exames</b-button
      > -->

      <b-button v-if="disableForm" variant="outline-success" @click="sair"
        >Sair</b-button
      >
    </div>
    <validation-observer ref="form">
      <b-form @submit.prevent>
        <b-row>
          <!-- matricula -->
          <b-col cols="12" lg="4">
            <b-form-group label="Número Atendimento" label-for="v-matricula">
              <b-form-input
                id="v-matricula"
                v-model="form.numeroAtendimento"
                placeholder="matricula"
                disabled
              />
            </b-form-group>
          </b-col>
          <!-- Data Nascimento -->
          <b-col cols="12" lg="4">
            <b-form-group label="Data Atendimento" label-for="v-data">
              <flat-pickr
                v-model="form.dataAtendimento"
                name="fieldDate"
                class="form-control"
                :disabled="true"
                :config="{
                  altInput: true,
                  altFormat: 'd/m/Y',
                  enableTime: false,
                  dateFormat: 'Y-m-d\\TH:i:ss\\Z'
                }"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" lg="4">
            <b-form-group label="Data Cancelamento" label-for="v-data">
              <flat-pickr
                id="fieldDateDisabled"
                v-model="form.dataCancelamento"
                class="form-control input-disabled bg-grey"
                :readonly="disableForm"
                disabled
                :config="{
                  altInput: true,
                  altFormat: 'd/m/Y',
                  enableTime: false,
                  dateFormat: 'Y-m-d\\TH:i:ss\\Z'
                }"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6" lg="4">
            <b-form-group
              label="Tipo de Atendimento"
              label-for="v-tipoAtendimentoDescricao"
            >
              <b-form-input
                id="v-tipoAtendimentoDescricao"
                v-model="form.tipoAtendimentoDescricao"
                placeholder="tipoAtendimentoDescricao"
                disabled
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6" lg="4">
            <b-form-group label="Especialidades" label-for="v-especialidade">
              <b-form-input
                id="v-especialidade"
                v-model="form.especialidadeDescricao"
                placeholder="especialidade"
                disabled
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="12" lg="4">
            <b-form-group label="Profissional" label-for="v-profissional">
              <b-form-input
                id="v-profissional"
                v-model="form.profissionalDescricao"
                placeholder="profissional"
                disabled
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" lg="12">
            <b-form-group label="Solicitante" label-for="v-solicitante">
              <b-form-input
                id="v-solicitante"
                v-model="form.solicitanteDescricao"
                placeholder="solicitante"
                disabled
              />
            </b-form-group>
          </b-col>

          <!--unidadeAtendimento -->
          <b-col cols="12" sm="12" lg="6">
            <b-form-group
              label="Unidade Atendimento"
              label-for="v-unidadeAtendimento"
            >
              <v-select
                v-model="form.unidadeAtendimento"
                :reduce="
                  (comboUnidadeAtendimento) => comboUnidadeAtendimento.id
                "
                label="nome"
                :options="comboUnidadeAtendimento"
                :disabled="disableForm"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" sm="12" lg="6">
            <b-form-group label="Situação" label-for="v-situacao">
              <v-select
                v-model="form.situacao"
                :reduce="(comboSituacao) => comboSituacao.id"
                label="nome"
                :options="comboSituacao"
                :disabled="disableForm"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" lg="6">
            <validation-provider
              #default="{ errors }"
              name="Hora Inicial"
              rules=""
            >
              <b-form-group label="Hora Inicial" label-for="v-horaInicial">
                <flat-pickr
                  v-model="form.horaInicial"
                  name="fieldDate"
                  :config="{
                    altInput: true,
                    enableTime: true,
                    noCalendar: true,
                    dateFormat: 'H:i',
                    time_24hr: true
                  }"
                  :disabled="disableForm"
                />
                <small data-cy="v-horaInicial-validate" class="text-danger">{{
                  errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" lg="6">
            <validation-provider
              #default="{ errors }"
              name="Hora Final"
              rules=""
            >
              <b-form-group label="Hora Final" label-for="v-horaFinal">
                <flat-pickr
                  v-model="form.horaFinal"
                  name="fieldDate"
                  :config="{
                    altInput: true,
                    enableTime: true,
                    noCalendar: true,
                    dateFormat: 'H:i',
                    time_24hr: true
                  }"
                  :disabled="disableForm"
                />
                <small data-cy="v-horaFinal-validate" class="text-danger">{{
                  errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" sm="12" md="12" lg="12">
            <b-form-group label="Observações" label-for="v-observacao">
              <b-form-textarea
                id="v-observacao"
                v-model="form.observacao"
                row="2"
                placeholder="Digite aqui suas observações"
                :disabled="disableForm"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" sm="12" md="12" lg="12"> </b-col>
          <b-col cols="12" lg="6">
            <b-calendar
              v-model="form.dataAtendimento"
              class="p-1"
              block
              :min="minDate"
              :max="maxDate"
              :date-disabled-fn="dateDisabled"
              :date-info-fn="dateClass"
              :disabled="disableForm"
              locale="pt-Br"
              @input="getHorarioSelecionado"
            />
          </b-col>
          <b-col v-if="!disableForm" cols="12" lg="6">
            <validation-provider
              #default="{ errors }"
              name="Horarios"
              rules="required"
            >
              <span v-if="bloqueado" class="p-1"
                >A data está bloqueada para agendamento.</span
              >
              <b-form-group label="Horarios" label-for="v-horario">
                <v-select
                  v-model="form.hora"
                  :options="comboProfissiocomboHorarioAgendanalsFiltrado"
                  :disabled="disableForm"
                />
                <small data-cy="v-solicitante" class="text-danger">{{
                  errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col v-else cols="12" lg="6">
            <b-form-group label="Horarios" label-for="v-horario">
              <b-form-input id="v-horario2" v-model="hora" disabled />
            </b-form-group>
          </b-col>
        </b-row>
        <ButtonsFormVue
          :disable-form="disableForm"
          :loading-button="loading"
          :permission-insert="verificarPermissao('ROLE_ATEND_INSERIR')"
          :permission-edit="verificarPermissao('ROLE_ATEND_ATUALIZAR')"
          @save="salvar"
          @edit="editar"
          @cancel="cancelar"
        />
      </b-form>
    </validation-observer>
  </b-card>
</template>
<script>
import { mapState } from 'vuex'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BCard,
  BFormTextarea,
  BCalendar
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import mixinsGlobal from '@/mixins'
import mixinsAtendimento from '@/mixins/atendimento.js'
import ButtonsFormVue from '@/layouts/components/ButtonsForm.vue'
import _ from 'lodash'

export default {
  name: 'FormEditarAtendimento',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BCard,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    vSelect,
    flatPickr,
    ButtonsFormVue,
    BCalendar
  },
  directives: {
    Ripple
  },
  mixins: [mixinsGlobal, mixinsAtendimento],
  computed: {
    ...mapState('combosModule', {
      comboTiposAtendimento: (state) => state.comboTiposAtendimento
    }),
    ...mapState('atendimentoModule', {
      atendimento: (state) => state.atendimento
    })
  },
  data() {
    return {
      required,
      loading: true,
      disableForm: true,
      form: {
        id: null,
        numeroAtendimento: '',
        dataAtendimento: '',
        observacao: '',
        situacao: {
          id: null,
          descricao: ''
        },
        dataCancelamento: null,
        profissional: {
          id: null,
          nome: '',
          especialidades: []
        },
        especialidade: {
          id: null,
          descricao: null
        },
        registro: {
          id: null,
          nome: ''
        },
        unidadeAtendimento: {
          id: null,
          descricao: null
        },
        agendamento: null,
        convenio: {
          id: null,
          descricao: null
        },
        tipoAtendimento: {
          id: null,
          descricao: ''
        },
        horaInicial: '',
        horaAgenda: null,
        horaFinal: null,
        campoAtendimento: [],
        hora: ''
      },
      comboEspecialidades: [],
      comboUnidadeAtendimento: [],
      comboSituacao: [],
      comboProfissionals: [],
      comboRegistros: [],
      minDate: '',
      maxDate: '',
      agendaProfissional: [],
      comboProfissiocomboHorarioAgendanals: [],
      comboProfissiocomboHorarioAgendanalsFiltrado: [],
      bloqueado: false,
      hora: null,
      report: {},
      comboExames: [],
      idExame: null
    }
  },
  async mounted() {
    await this.loadComboUnidadeAtendimento()
    await this.loadComboSituacao()
    this.loadDadosForm()
  },
  methods: {
    exame() {
      this.report = {}
      this.report = {
        nome: 'solicitacao_exame',
        valor: [parseInt(this.form.id)]
      }
      if (this.report.nome === 'solicitacao_exame') {
        this.showModal()
        return
      }
    },
    printRoportExame() {
      this.report = {
        nome: 'solicitacao_exame',
        valor: []
      }
      this.report.valor.push(parseInt(this.form.id))
      this.report.valor.push(parseInt(this.idExame.id))
      this.$store
        .dispatch('reportModule/printReport', this.report)
        .then((response) => {
          if (response.error) {
            this.showMessageSuccess('Limite Excedido', response.message)
          }
          this.openNewAbaBase64(response.data)
        })
    },
    async showModal() {
      const response = await this.$store.dispatch(
        'combosModule/loadComboEspecialidadesExamesAtendimento',
        this.form.especialidade.id
      )
      this.comboExames = response.data
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    atestado() {
      this.report = {}
      this.report = {
        nome: 'atestado_atendimento',
        valor: [parseInt(this.form.id)]
      }
      this.$store
        .dispatch('reportModule/printReport', this.report)
        .then((response) => {
          if (response.error) {
            this.showMessageSuccess('Limite Excedido', response.message)
          }
          this.openNewAbaBase64(response.data)
        })
    },
    receita() {
      this.report = {}
      this.report = {
        nome: 'receita_atendimento',
        valor: [parseInt(this.form.id)]
      }
      this.$store
        .dispatch('reportModule/printReport', this.report)
        .then((response) => {
          if (response.error) {
            this.showMessageSuccess('Limite Excedido', response.message)
          }
          this.openNewAbaBase64(response.data)
        })
    },
    declaracao() {
      this.report = {}
      this.report = {
        nome: 'declaracao_atendimento',
        valor: [parseInt(this.form.id)]
      }
      this.$store
        .dispatch('reportModule/printReport', this.report)
        .then((response) => {
          if (response.error) {
            this.showMessageSuccess('Limite Excedido', response.message)
          }
          this.openNewAbaBase64(response.data)
        })
    },
    loadDadosForm() {
      this.loading = true
      this.$store
        .dispatch('atendimentoModule/getAtendimentoById', this.$route.params.id)
        .then(() => {
          this.setObejetoLocalEdicao()
          this.aplicarEstiloDisabilitadoEmCampoData()
          this.aplicarEstiloDisabilitado()
          this.getGrupos()
          this.getAgendas()
          this.hora = this.form.hora
        })
        .finally(() => {
          this.loading = false
        })
    },
    formatDate(dateString) {
      return dateString.split('T')[0]
    },

    aplicarEstiloDisabilitado() {
      const element = document.getElementById('fieldDateDisabled')
      element.nextElementSibling.style.backgroundColor = '#efefef'
    },
    sair() {
      // this.$router.push({ name: 'AttendancePage' })
      this.$router.go(-1)
    },
    async loadComboEspecialidades() {
      this.comboEspecialidades = []
      this.$store
        .dispatch(
          'combosModule/loadComboEspecialidadesByIdTipoAtendimento',
          this.form.tipoAtendimento
        )
        .then((response) => {
          this.comboEspecialidades = response.data
        })
    },
    async loadComboUnidadeAtendimento() {
      this.$store
        .dispatch('combosModule/loadComboUnidadeAtendimento')
        .then((response) => {
          this.comboUnidadeAtendimento = response.data.map((item) => ({
            id: item.id,
            nome: item.descricao
          }))
        })
    },
    async loadComboSituacao() {
      this.$store
        .dispatch('combosModule/loadComboSituacao')
        .then((response) => {
          this.comboSituacao = response.data.map((item) => ({
            id: item.id,
            nome: item.descricao
          }))
        })
    },
    setObejetoLocalEdicao() {
      this.form = {
        id: this.atendimento.id,
        numeroAtendimento: this.atendimento.numeroAtendimento,
        dataAtendimento: this.atendimento.dataAtendimento,
        observacao: this.atendimento.observacao,
        situacao: this.atendimento.situacao.id,
        dataCancelamento: this.atendimento.dataCancelamento,
        profissional: this.atendimento.profissional.id,
        profissionalDescricao: this.atendimento.profissional.nome,
        especialidade: this.atendimento.especialidade.id,
        especialidadeDescricao: this.atendimento.especialidade.descricao,
        registro: this.atendimento.registro.id,
        solicitanteDescricao: this.atendimento.registro.nome,
        unidadeAtendimento: this.atendimento.unidadeAtendimento.id,
        agendamento: this.atendimento.agendamento,
        convenio: this.atendimento.convenio.id,
        tipoAtendimento: this.atendimento.tipoAtendimento.id,
        tipoAtendimentoDescricao: this.atendimento.tipoAtendimento.descricao,
        horaInicial: this.atendimento.horaInicial,
        horaAgenda: this.atendimento.horaAgenda,
        horaFinal: this.atendimento.horaFinal,
        campoAtendimento: this.atendimento.campoAtendimento,
        hora: this.atendimento.horaAgenda
      }
    },
    resetForm() {
      this.form = {}
      this.setObejetoLocalEdicao()
    },
    getAgendas() {
      this.$store
        .dispatch(
          'atendimentoModule/getAgendaProfissional',
          this.atendimento.profissional.id
        )
        .then(async (response) => {
          this.agendaProfissional = response.data
          if (this.agendaProfissional.length > 0) {
            this.minAndMaxDates()
          }
        })
    },
    async editar() {
      this.disableForm = false
      this.form.dataAtendimento = this.formatDate(this.form.dataAtendimento)
      this.aplicarEstiloDisabilitadoEmCampoData()
      this.getAgendas()
    },
    cancelar() {
      this.disableForm = true
      this.aplicarEstiloDisabilitadoEmCampoData()
      this.setObejetoLocalEdicao()
      this.$refs.form.reset()
    },
    prepareForSave(data) {
      const objectSave = JSON.parse(JSON.stringify(data))
      const obj = {
        id: objectSave.id,
        numeroAtendimento: objectSave.numeroAtendimento,
        dataAtendimento: objectSave.dataAtendimento,
        observacao: objectSave.observacao,
        situacao: objectSave.situacao,
        dataCancelamento: objectSave.dataCancelamento,
        profissional: { id: objectSave.profissional },
        profissionalDescricao: objectSave.profissional.nome,
        especialidade: objectSave.especialidade,
        especialidadeDescricao: objectSave.especialidade
          ? objectSave.especialidade.descricao
          : '',
        registro: objectSave.registro,
        solicitanteDescricao: objectSave.registro.nome,
        unidadeAtendimento: objectSave.unidadeAtendimento,
        agendamento: objectSave.agendamento,
        convenio: objectSave.convenio,
        tipoAtendimento: objectSave.tipoAtendimento,
        tipoAtendimentoDescricao: objectSave.tipoAtendimento
          ? objectSave.tipoAtendimento.descricao
          : '',
        horaInicial: objectSave.horaInicial,
        horaAgenda: objectSave.horaAgenda,
        horaFinal: objectSave.horaFinal,
        campoAtendimento: objectSave.campoAtendimento,
        hora: objectSave.hora.value
      }
      return obj
    },
    salvar() {
      this.$refs.form.validate().then((success) => {
        const objectSave = this.prepareForSave(this.form)
        if (success) {
          this.loading = true
          try {
            this.$store
              .dispatch('atendimentoModule/edit', objectSave)
              .then((response) => {
                if (
                  response.response &&
                  response.response.data &&
                  response.response.data.error
                ) {
                  this.MensagemError('Editar', 'Falha ao editar!')
                  return
                }
                this.showMessageSuccess('Editar', 'Editado com sucesso!')
                this.cancelar()
                this.loadDadosForm()
                this.form.hora = response.data.horaAgenda
                this.loading = false
              })
            return
          } catch (error) {
            this.MensagemError('Editar', 'Falha ao editar!')
            this.loading = false
          }
        }
      })
    },
    getGrupos() {
      this.$emit('get-grupos')
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
