<template>
  <div v-if="loading" class="clearfix">
    <b-spinner class="float-right" label="Floated Right" />
  </div>
  <div v-else>
    <b-tabs>
      <b-tab>
        <template #title>
          <feather-icon icon="ClockIcon" />
          <span>Atendimentos </span>
        </template>
        <app-collapse type="margin">
          <app-collapse-item title="Atendimentos" :isVisible="true">
            <FormDadosAtendimentoVue @get-grupos="getGrupos" />
          </app-collapse-item>
        </app-collapse>
      </b-tab>
      <b-tab v-for="item in listaGrupos" :key="item.id">
        <template #title>
          <feather-icon icon="CheckIcon" />
          <span>{{ item.grupo }}</span>
           
          <b-spinner
            v-if="loadingCampos"
            class="float-right"
            label="Floated Right"
            small
          />
        </template>
        <app-collapse
          v-for="procedimento in item.procedimentos"
          :key="procedimento.id"
          type="margin"
        >
          <app-collapse-item :isVisible="true" :title="procedimento.nome">
            <b-row
              v-if="
                item.procedimentos.length === 0 ||
                procedimento.campo_adicional.length === 0
              "
            >
              <b-col> Nenhum campo configurado </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                :sm="campo.tipo === TYPES_FIELDS.TEXTO ? '12' : '6'"
                v-for="campo in procedimento.campo_adicional"
                :key="campo.id"
              >
                <b-form-group
                  v-if="campo.tipo === TYPES_FIELDS.STRING"
                  :label="campo.nome"
                >
                  <b-form-input
                    :id="'id_' + campo.id"
                    v-model="campo.valor"
                    type="text"
                    :disabled="disableForm"
                  />
                </b-form-group>

                <b-form-group
                  v-if="campo.tipo === TYPES_FIELDS.TEXTO"
                  :label="campo.nome"
                >
                  <quill-editor
                    :id="'id_' + campo.id"
                    v-model="campo.valor"
                    :options="editorOptions"
                    :disabled="disableForm"
                    :class="{ 'ql-disabled': disableForm }"
                  />
                </b-form-group>

                <b-form-group
                  v-if="
                    campo.tipo === TYPES_FIELDS.INTEGER ||
                    campo.tipo === TYPES_FIELDS.DECIMAL
                  "
                  :label="campo.nome"
                >
                  <b-form-input
                    :id="'id_' + campo.id"
                    v-model="campo.valor"
                    type="number"
                    :disabled="disableForm"
                  />
                </b-form-group>

                <b-form-group
                  v-if="campo.tipo === TYPES_FIELDS.DATA"
                  :label="campo.nome"
                >
                  <flat-pickr
                    :id="'id_' + campo.id"
                    v-model="campo.valor"
                    name="fieldDate"
                    class="form-control"
                    :config="{
                      altInput: true,
                      altFormat: 'd/m/Y',
                      enableTime: false,
                      dateFormat: 'Y-m-d\\TH:i:ss\\Z'
                    }"
                    :readonly="disableForm"
                    :disabled="disableForm"
                  />
                </b-form-group>

                <b-form-group
                  v-if="campo.tipo === TYPES_FIELDS.BOOLEAN"
                  :label="campo.nome"
                >
                  <b-form-checkbox
                    :id="'id_' + campo.id"
                    v-model="campo.valor"
                    name="check-button"
                    :value="'true'"
                    :unchecked-value="'false'"
                    :disabled="disableForm"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </app-collapse-item>
        </app-collapse>
        <ButtonsFormVue
          :disable-form="disableForm"
          :loading-button="loadingCampos"
          :permission-insert="verificarPermissao('ROLE_ATEND_INSERIR')"
          :permission-edit="verificarPermissao('ROLE_ATEND_ATUALIZAR')"
          @save="salvarCamposAdicionais"
          @edit="editar"
          @cancel="cancelar"
        />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import {
  BRow,
  BCol,
  BSpinner,
  BFormGroup,
  BFormInput,
  BTabs,
  BTab,
  BFormCheckbox,
  BFormTextarea
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import flatPickr from 'vue-flatpickr-component'
import ButtonsFormVue from '@/layouts/components/ButtonsForm.vue'
import mixinsGlobal from '@/mixins'
import FormDadosAtendimentoVue from './FormDadosAtendimento.vue'
import { quillEditor } from 'vue-quill-editor'

// Constantes extraídas para melhorar legibilidade
const TYPES_FIELDS = {
  STRING: 0,
  BOOLEAN: 1,
  DECIMAL: 2,
  INTEGER: 3,
  DATA: 4,
  TEXTO: 5
}

// Tempo de inatividade em milissegundos (3 minutos)
const INACTIVITY_TIMEOUT = 3 * 60 * 1000

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
    BRow,
    BCol,
    BSpinner,
    FormDadosAtendimentoVue,
    BFormGroup,
    BFormInput,
    BTabs,
    BTab,
    flatPickr,
    ButtonsFormVue,
    BFormCheckbox,
    BFormTextarea,
    quillEditor
  },
  mixins: [mixinsGlobal],

  computed: {
    ...mapState('atendimentoModule', {
      atendimento: (state) => state.atendimento
    })
  },

  data() {
    return {
      collapseType: 'default',
      listaGrupos: [],
      loading: false,
      loadingCampos: false,
      disableForm: true,
      TYPES_FIELDS,
      inactivityTimer: null,
      inactivityTimeout: INACTIVITY_TIMEOUT,
      lastActivity: Date.now(),
      editorOptions: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            [{ header: 1 }, { header: 2 }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ direction: 'rtl' }],
            [{ size: ['small', false, 'large', 'huge'] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
            [{ font: [] }],
            [{ align: [] }],
            ['clean'],
            ['link', 'image']
          ]
        },
        placeholder: 'Digite o conteúdo aqui...',
        theme: 'snow'
      }
    }
  },

  mounted() {
    this.setupActivityListeners()
  },

  beforeDestroy() {
    this.removeActivityListeners()
    this.clearInactivityTimer()
  },

  methods: {
    // Métodos de ciclo de vida e configuração
    setupActivityListeners() {
      const events = [
        'mousemove',
        'mousedown',
        'keypress',
        'touchmove',
        'click',
        'scroll'
      ]
      events.forEach((event) => {
        document.addEventListener(event, this.resetInactivityTimer)
      })
    },

    removeActivityListeners() {
      const events = [
        'mousemove',
        'mousedown',
        'keypress',
        'touchmove',
        'click',
        'scroll'
      ]
      events.forEach((event) => {
        document.removeEventListener(event, this.resetInactivityTimer)
      })
    },

    // Métodos de gerenciamento de inatividade
    startInactivityTimer() {
      this.resetInactivityTimer()
      this.inactivityTimer = setInterval(() => {
        const currentTime = Date.now()
        const timeSinceLastActivity = currentTime - this.lastActivity

        if (
          timeSinceLastActivity >= this.inactivityTimeout &&
          !this.disableForm
        ) {
          this.autoSave()
        }
      }, 10000) // Verificar a cada 10 segundos
    },

    resetInactivityTimer() {
      this.lastActivity = Date.now()
    },

    clearInactivityTimer() {
      if (this.inactivityTimer) {
        clearInterval(this.inactivityTimer)
        this.inactivityTimer = null
      }
    },

    autoSave() {
      this.salvarCamposAdicionais(true)
    },

    // Métodos de carregamento de dados
    getGrupos() {
      this.loading = true
      this.montarAbas()
      this.loading = false
    },

    montarAbas() {
      this.$store
        .dispatch('atendimentoModule/montarTela', this.$route.params.id)
        .then((response) => {
          this.loadListaGrupos(response.data)
        })
        .catch((error) => {
          this.MensagemError('Carregar', 'Falha ao carregar os dados!')
        })
    },

    loadListaGrupos(atendimento) {
      this.listaGrupos = this.extrairGruposProcedimentos(
        atendimento.atendimento.grupo_procedimento
      )
    },

    extrairGruposProcedimentos(gruposProcedimento) {
      const grupos = []

      gruposProcedimento.forEach((grupo) => {
        if (grupo.id !== 0) {
          const grupoFormatado = {
            id: grupo.id,
            grupo: grupo.nome ? grupo.nome : 'Padrão',
            procedimentos: this.formatarProcedimentos(grupo.procedimento)
          }
          grupos.push(grupoFormatado)
        }
      })

      return grupos
    },

    formatarProcedimentos(procedimentos) {
      return procedimentos.map((procedimento) => {
        const procedimentoFormatado = { ...procedimento }
        procedimentoFormatado.campo_adicional =
          procedimento.campo_adicional.map((campo) => {
            return {
              ...campo,
              valor:
                campo.campo_atendimento.length > 0
                  ? campo.campo_atendimento[0]?.valor
                  : null
            }
          })
        return procedimentoFormatado
      })
    },

    // Métodos de ações do usuário
    async editar() {
      this.disableForm = false
      this.aplicarEstiloDisabilitadoEmCampoData()
      this.startInactivityTimer()
    },

    cancelar() {
      this.disableForm = true
      this.loadingCampos = false
      this.aplicarEstiloDisabilitadoEmCampoData()
      this.clearInactivityTimer()
    },

    // Métodos de salvamento
    salvarCamposAdicionais(isAutoSave = false) {
      this.loadingCampos = true
      const camposParaSalvar = this.prepararCamposParaSalvar()

      this.$store
        .dispatch('atendimentoModule/editCamposAdicionais', camposParaSalvar)
        .then((response) => {
          this.handleSalvarResponse(response, isAutoSave)
        })
        .catch((error) => {
          this.MensagemError('Editar', 'Falha ao editar!')
          this.loadingCampos = false
        })
    },

    prepararCamposParaSalvar() {
      const listaSalvarCampos = []

      this.listaGrupos.forEach((grupo) => {
        grupo.procedimentos.forEach((procedimento) => {
          procedimento.campo_adicional.forEach((campo) => {
            listaSalvarCampos.push({
              atendimento: this.$route.params.id,
              campoAdicional: campo.id,
              valor: campo.valor ? campo.valor : null
            })
          })
        })
      })

      return listaSalvarCampos
    },

    handleSalvarResponse(response, isAutoSave) {
      if (response.response?.data?.error) {
        this.MensagemError('Editar', 'Falha ao editar!')
        this.loadingCampos = false
        return
      }

      if (isAutoSave) {
        this.showMessageSuccess('Salvar', 'Salvo automaticamente com sucesso!')
        this.loadingCampos = false
        this.resetInactivityTimer()
      } else {
        this.showMessageSuccess('Editar', 'Editado com sucesso!')
        this.cancelar()
      }
    },

    handleEditResponse(response) {
      if (response.response?.data?.error) {
        this.MensagemError('Editar', 'Falha ao editar!')
        return
      }

      this.showMessageSuccess('Editar', 'Editado com sucesso!')
      this.cancelar()
      this.loadCamposAdicionais()
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
// Adicione os estilos do Quill
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';
@import '~quill/dist/quill.bubble.css';
</style>
<style>
.teeth {
  width: 0;
  height: 0;
  border-left: 40px solid transparent;
  border-right: 40px solid transparent;
  border-bottom: 80px solid lightblue;
}
.disabled {
  pointer-events: none;
  opacity: 0.5; /* Você pode adicionar um efeito visual para indicar que o editor está desabilitado */
}

/* Estilos para o Quill Editor */
.ql-disabled {
  pointer-events: none;
  opacity: 0.5;
}

.ql-editor {
  min-height: 150px;
}

.quill-editor {
  margin-bottom: 1rem;
}
</style>
