var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loading)?_c('div',{staticClass:"clearfix"},[_c('b-spinner',{staticClass:"float-right",attrs:{"label":"Floated Right"}})],1):_c('div',[_c('b-tabs',[_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ClockIcon"}}),_c('span',[_vm._v("Atendimentos ")])]},proxy:true}])},[_c('app-collapse',{attrs:{"type":"margin"}},[_c('app-collapse-item',{attrs:{"title":"Atendimentos","isVisible":true}},[_c('FormDadosAtendimentoVue',{on:{"get-grupos":_vm.getGrupos}})],1)],1)],1),_vm._l((_vm.listaGrupos),function(item){return _c('b-tab',{key:item.id,scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('feather-icon',{attrs:{"icon":"CheckIcon"}}),_c('span',[_vm._v(_vm._s(item.grupo))]),(_vm.loadingCampos)?_c('b-spinner',{staticClass:"float-right",attrs:{"label":"Floated Right","small":""}}):_vm._e()]},proxy:true}],null,true)},[_vm._l((item.procedimentos),function(procedimento){return _c('app-collapse',{key:procedimento.id,attrs:{"type":"margin"}},[_c('app-collapse-item',{attrs:{"isVisible":true,"title":procedimento.nome}},[(
              item.procedimentos.length === 0 ||
              procedimento.campo_adicional.length === 0
            )?_c('b-row',[_c('b-col',[_vm._v(" Nenhum campo configurado ")])],1):_vm._e(),_c('b-row',_vm._l((procedimento.campo_adicional),function(campo){return _c('b-col',{key:campo.id,attrs:{"cols":"12","sm":campo.tipo === _vm.TYPES_FIELDS.TEXTO ? '12' : '6'}},[(campo.tipo === _vm.TYPES_FIELDS.STRING)?_c('b-form-group',{attrs:{"label":campo.nome}},[_c('b-form-input',{attrs:{"id":'id_' + campo.id,"type":"text","disabled":_vm.disableForm},model:{value:(campo.valor),callback:function ($$v) {_vm.$set(campo, "valor", $$v)},expression:"campo.valor"}})],1):_vm._e(),(campo.tipo === _vm.TYPES_FIELDS.TEXTO)?_c('b-form-group',{attrs:{"label":campo.nome}},[_c('quill-editor',{class:{ 'ql-disabled': _vm.disableForm },attrs:{"id":'id_' + campo.id,"options":_vm.editorOptions,"disabled":_vm.disableForm},model:{value:(campo.valor),callback:function ($$v) {_vm.$set(campo, "valor", $$v)},expression:"campo.valor"}})],1):_vm._e(),(
                  campo.tipo === _vm.TYPES_FIELDS.INTEGER ||
                  campo.tipo === _vm.TYPES_FIELDS.DECIMAL
                )?_c('b-form-group',{attrs:{"label":campo.nome}},[_c('b-form-input',{attrs:{"id":'id_' + campo.id,"type":"number","disabled":_vm.disableForm},model:{value:(campo.valor),callback:function ($$v) {_vm.$set(campo, "valor", $$v)},expression:"campo.valor"}})],1):_vm._e(),(campo.tipo === _vm.TYPES_FIELDS.DATA)?_c('b-form-group',{attrs:{"label":campo.nome}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"id":'id_' + campo.id,"name":"fieldDate","config":{
                    altInput: true,
                    altFormat: 'd/m/Y',
                    enableTime: false,
                    dateFormat: 'Y-m-d\\TH:i:ss\\Z'
                  },"readonly":_vm.disableForm,"disabled":_vm.disableForm},model:{value:(campo.valor),callback:function ($$v) {_vm.$set(campo, "valor", $$v)},expression:"campo.valor"}})],1):_vm._e(),(campo.tipo === _vm.TYPES_FIELDS.BOOLEAN)?_c('b-form-group',{attrs:{"label":campo.nome}},[_c('b-form-checkbox',{attrs:{"id":'id_' + campo.id,"name":"check-button","value":'true',"unchecked-value":'false',"disabled":_vm.disableForm},model:{value:(campo.valor),callback:function ($$v) {_vm.$set(campo, "valor", $$v)},expression:"campo.valor"}})],1):_vm._e()],1)}),1)],1)],1)}),_c('ButtonsFormVue',{attrs:{"disable-form":_vm.disableForm,"loading-button":_vm.loadingCampos,"permission-insert":_vm.verificarPermissao('ROLE_ATEND_INSERIR'),"permission-edit":_vm.verificarPermissao('ROLE_ATEND_ATUALIZAR')},on:{"save":_vm.salvarCamposAdicionais,"edit":_vm.editar,"cancel":_vm.cancelar}})],2)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }